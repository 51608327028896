'use client';
import React from 'react';
import Link from 'next/link';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import useLoginStore from '@/stores/useLoginStore';
import { useToast } from '@/hooks/use-toast';
import { useSession } from 'next-auth/react';
import { UserMenu } from '@/components/shared/usermenu/UserMenu';
import { User } from '@/types/User';
import { Mail, Lock, LogIn, Package, PawPrint } from 'lucide-react';

export function Header2() {
  const { data: session, status } = useSession();

  return (
    <header className='sticky top-0 z-50 w-full border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60'>
      <div className='mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8'>
        <Link className='flex items-center justify-center gap-2' href='/'>
          <span className='text-xl font-bold text-primary'>BarkOnTrack</span>
        </Link>
        <div className='flex items-center gap-4'>
          {status === 'loading' ? (
            <Button variant='outline' disabled loading className='min-w-[100px]' />
          ) : session?.user ? (
            <UserMenu user={session.user as User} />
          ) : (
            <>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant='default' className='bg-blue-600 hover:bg-blue-700'>
                    <LogIn className='mr-2 h-5 w-5' />
                    Sign In
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-[380px] p-0' align='end'>
                  <LoginForm />
                </PopoverContent>
              </Popover>
              <Button
                variant='outline'
                className='border-2 border-blue-200 hover:bg-blue-50'
                onClick={() => (window.location.href = 'https://8naqjmqrv07.typeform.com/to/KfIuMeYb')}
              >
                Get Started
              </Button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

function LoginForm() {
  const { email, password, requestInProgress, setEmail, setPassword, setRequestInProgress } = useLoginStore();
  const { toast } = useToast();
  const router = useRouter();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    try {
      const res = await signIn('credentials', {
        username: email,
        password: password,
        redirect: false,
        callbackUrl: '/',
      });
      if (res?.error) {
        toast({
          title: 'Error',
          description: 'Invalid email or password',
          variant: 'destructive',
        });
      } else if (res?.ok) {
        toast({
          title: 'Success',
          description: 'Logged in successfully',
        });
        router.push('/dashboard');
        window.location.href = '/dashboard';
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong',
        variant: 'destructive',
      });
    }
    setRequestInProgress(false);
  };

  return (
    <div className='relative overflow-hidden'>
      <div className='relative bg-gradient-to-br from-blue-600 to-blue-700 px-6 py-8'>
        <div className='relative z-10 text-center'>
          <h3 className='text-2xl font-bold text-white'>Welcome Back</h3>
          <p className='mt-2 text-blue-100'>Sign in to access your account</p>
        </div>
        <div className='absolute inset-0 bg-[linear-gradient(to_right,#4f46e5,#0ea5e9)] opacity-10' />
        <div className='absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-blue-200 to-transparent' />
      </div>

      <form onSubmit={handleSubmit} className='space-y-6 bg-white p-6'>
        <div className='space-y-2'>
          <Label className='text-sm font-medium text-gray-700' htmlFor='email'>
            Email Address
          </Label>
          <div className='relative'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <Mail className='h-5 w-5 text-blue-500' />
            </div>
            <Input
              id='email'
              type='email'
              placeholder='name@example.com'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='pl-10 focus-visible:ring-blue-500'
              required
            />
          </div>
        </div>

        <div className='space-y-2'>
          <div className='flex items-center justify-between'>
            <Label className='text-sm font-medium text-gray-700' htmlFor='password'>
              Password
            </Label>
            <Link
              href='/forgot-password'
              className='text-sm font-medium text-blue-600 transition-colors hover:text-blue-700'
            >
              Forgot password?
            </Link>
          </div>
          <div className='relative'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <Lock className='h-5 w-5 text-blue-500' />
            </div>
            <Input
              id='password'
              type='password'
              placeholder='••••••••'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='pl-10 focus-visible:ring-blue-500'
              required
            />
          </div>
        </div>

        <Button
          type='submit'
          className='w-full bg-blue-600 text-base hover:bg-blue-700'
          size='lg'
          disabled={requestInProgress}
          loading={requestInProgress}
        >
          Sign In
        </Button>

        <div className='relative py-6'>
          <div className='absolute inset-0 flex items-center'>
            <div className='w-full border-t border-gray-200' />
          </div>
          <div className='relative flex justify-center text-sm'>
            <span className='bg-white px-2 text-gray-500'>Don&apos;t have an account?</span>
          </div>
        </div>

        <Button
          type='button'
          variant='outline'
          className='w-full border-2 border-blue-200 text-base hover:bg-blue-50'
          size='lg'
          onClick={() => (window.location.href = 'https://8naqjmqrv07.typeform.com/to/KfIuMeYb')}
        >
          Create Account
        </Button>
      </form>
    </div>
  );
}
