'use client';

import { Header2 } from '@/components/landing2/Header2';
import { Landing2 } from '@/components/landing2/Landing2';
import Footer from '@/components/shared/Footer';

export default function Home() {
  return (
    <div>
      <Header2 />
      <Landing2 />
      <Footer />
    </div>
  );
}
