'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import {
  PawPrint,
  BarChart3,
  Calendar,
  Users,
  ClipboardCheck,
  Shield,
  ArrowRight,
  LayoutDashboard,
  Clock,
  Target,
  Sparkles,
} from 'lucide-react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

function FeatureItem({ icon, title, description }) {
  return (
    <div className='group rounded-lg border border-border bg-card p-6 transition-colors hover:bg-accent'>
      <div className='mb-4 flex h-14 w-14 items-center justify-center rounded-xl bg-primary/10 text-primary transition-colors group-hover:bg-primary/20'>
        {icon}
      </div>
      <h3 className='text-xl font-bold text-foreground'>{title}</h3>
      <p className='mt-2 text-sm leading-6 text-muted-foreground'>{description}</p>
    </div>
  );
}

function FeatureHighlight({ icon: Icon, title, description }) {
  return (
    <div className='flex flex-col space-y-4 rounded-2xl bg-card p-8 shadow-sm ring-1 ring-border'>
      <div className='flex h-12 w-12 items-center justify-center rounded-xl bg-primary/10'>
        <Icon className='h-6 w-6 text-primary' />
      </div>
      <div>
        <h3 className='text-xl font-bold text-foreground'>{title}</h3>
        <p className='mt-2 text-sm leading-6 text-muted-foreground'>{description}</p>
      </div>
    </div>
  );
}

function CheckCircle({ className }) {
  return (
    <svg className={className} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function Landing2() {
  return (
    <main className='flex-1'>
      <HeroSection />
      <FeaturesSection />
      <BenefitsSection />
      <KeyFeaturesSection />
      <CTASection />
    </main>
  );
}

function HeroSection() {
  const { data: session, status } = useSession();
  const [loadingDashboard, setLoadingDashboard] = useState(false);

  return (
    <section className='relative isolate overflow-hidden bg-background'>
      <div className='mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:items-start lg:gap-x-20 lg:px-8 lg:py-40'>
        <div className='mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:-mt-8 lg:max-w-xl'>
          <h1 className='text-4xl font-bold tracking-tight text-foreground sm:text-6xl'>
            Keep Your Training
            <span className='block text-primary'>Business on Track</span>
          </h1>
          <p className='mt-6 text-lg leading-8 text-muted-foreground'>
            BarkOnTrack helps professional dog trainers manage clients, schedule sessions, and grow their business with
            ease.
          </p>

          <div className='mt-8 flex flex-col gap-4 sm:flex-row'>
            {status === 'loading' ? (
              <Button size='lg' disabled loading className='w-full sm:w-auto'>
                Loading...
              </Button>
            ) : session?.user ? (
              <Link href='/dashboard'>
                <Button
                  size='lg'
                  onClick={() => setLoadingDashboard(true)}
                  className='inline-flex items-center gap-2'
                  loading={loadingDashboard}
                  disabled={loadingDashboard}
                >
                  <LayoutDashboard className='h-5 w-5' />
                  Go to Dashboard
                </Button>
              </Link>
            ) : (
              <>
                <a
                  href='https://8naqjmqrv07.typeform.com/to/KfIuMeYb'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='inline-flex items-center justify-center rounded-md bg-primary px-6 py-3 text-base font-medium text-primary-foreground shadow-sm transition-all hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2'
                >
                  Start Free Trial
                </a>

                <p className='mt-3 text-sm text-muted-foreground'>
                  No credit card required • 14-day free trial • Cancel anytime
                </p>
              </>
            )}
          </div>
        </div>

        <div className='mx-auto mt-12 w-full lg:ml-10 lg:mt-0 lg:flex-1 xl:ml-32'>
          <div className='relative w-full'>
            <div className='relative rounded-xl bg-muted/50 p-2 ring-1 ring-inset ring-border lg:-m-4 lg:p-4'>
              <div className='relative aspect-[16/9] w-full overflow-hidden rounded-lg bg-muted shadow-2xl'>
                <Image
                  src='/wade-austin-ellis-FtuJIuBbUhI-unsplash.jpg'
                  alt='Dog training management dashboard'
                  width={1200}
                  height={675}
                  priority
                  className='h-full w-full object-cover'
                  sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 800px'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function FeaturesSection() {
  return (
    <section className='bg-muted/50 py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-foreground sm:text-4xl'>
            Everything You Need to Succeed
          </h2>
          <p className='mt-4 text-lg leading-8 text-muted-foreground'>
            Powerful tools designed specifically for professional dog trainers
          </p>
        </div>
        <div className='mx-auto mt-16 grid max-w-7xl grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
          <FeatureItem
            icon={<Calendar className='h-8 w-8' />}
            title='Smart Scheduling'
            description='Efficiently manage your training sessions and client appointments'
          />
          <FeatureItem
            icon={<ClipboardCheck className='h-8 w-8' />}
            title='Progress Tracking'
            description="Track and document each client's training journey"
          />
          <FeatureItem
            icon={<BarChart3 className='h-8 w-8' />}
            title='Business Analytics'
            description='Gain insights into your business performance'
          />
          <FeatureItem
            icon={<Users className='h-8 w-8' />}
            title='Client Management'
            description='Organize client information and communications'
          />
          <FeatureItem
            icon={<PawPrint className='h-8 w-8' />}
            title='Training Plans'
            description='Create and manage customized training programs'
          />
          <FeatureItem
            icon={<Shield className='h-8 w-8' />}
            title='Secure Platform'
            description='Keep your business data safe and protected'
          />
        </div>
      </div>
    </section>
  );
}

function BenefitsSection() {
  return (
    <section className='overflow-hidden bg-white py-24 dark:bg-gray-900 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='relative isolate overflow-hidden rounded-3xl bg-gray-100 px-6 py-16 shadow-2xl dark:bg-gray-800 sm:px-16 sm:py-24 lg:py-32'>
          <div className='mx-auto max-w-7xl lg:grid lg:grid-cols-2 lg:gap-x-16'>
            <div className='lg:col-span-1'>
              <h3 className='text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl'>
                Transform Your Training Business
              </h3>
              <p className='mt-6 text-lg leading-8 text-gray-700 dark:text-gray-300'>
                Join the successful dog trainers who have streamlined their business with BarkOnTrack.
              </p>
            </div>
            <div className='mt-10 lg:mt-0'>
              <dl className='space-y-6'>
                {[
                  'Save 10+ hours per week on administration',
                  'Increase client satisfaction by 40%',
                  'Grow revenue with better business insights',
                  'Manage more clients with less effort',
                ].map((benefit, index) => (
                  <div key={index} className='flex items-center gap-x-4'>
                    <CheckCircle className='h-6 w-6 flex-none text-gray-900 dark:text-gray-100' />
                    <p className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-100'>{benefit}</p>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function KeyFeaturesSection() {
  return (
    <section className='bg-muted/50 py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <h2 className='text-center text-3xl font-bold tracking-tight text-foreground sm:text-4xl'>
          Key Features That Make a Difference
        </h2>
        <p className='mt-4 text-center text-lg text-muted-foreground'>
          Designed specifically for professional dog trainers to streamline their business
        </p>
        <div className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3'>
          <FeatureHighlight
            icon={Clock}
            title='Time-Saving Automation'
            description='Automate scheduling, reminders, and routine tasks to focus more on training and less on administration.'
          />
          <FeatureHighlight
            icon={Target}
            title='Goal-Oriented Training'
            description='Set, track, and achieve training goals with structured progress tracking and detailed session notes.'
          />
          <FeatureHighlight
            icon={Sparkles}
            title='Professional Experience'
            description='Deliver a polished, professional experience to your clients with organized scheduling and clear communication.'
          />
        </div>
      </div>
    </section>
  );
}

function CTASection() {
  return (
    <section className='bg-muted/50'>
      <div className='mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-foreground sm:text-4xl'>
            Ready to grow your training business?
          </h2>
          <div className='mt-10 flex items-center justify-center'>
            <Button className='px-8 py-6 text-lg font-semibold' size='lg'>
              Get Started Now
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
